<template>
	<el-card class="box-card" shadow="never" style="margin-top: 20px;">
		<h3>{{$t('wallet')}}</h3>
		<div class="item">
			<div class="label">{{baseCoin}}</div>
			<div class="value">{{club_balance|filtersUnits|toFloor}}</div>
		</div>
		<div class="item">
			<div class="label">USDT</div>
			<div class="value">{{usdt_balance|filtersUnits|toFloor}}</div>
		</div>
		<div class="item">
			<div class="label">BNB</div>
			<div class="value">{{ht_balance|filtersUnits|toFloor}}</div>
		</div>
		<div class="item">
			<div class="label">LP(USDT-{{baseCoin}})</div>
			<div class="value">{{lp_banance|filtersUnits|toFloor}}</div>
		</div>
		<div class="bottom" style="align-items: center; justify-content:space-between;">
			<el-link class="el-button--primary" :underline="false" :href="add_url">{{$t('add_liu')}}</el-link>
			<el-link class="el-button--primary" :underline="false" :href="removeLink">{{$t('remove_liu')}}</el-link>
		</div>
	</el-card>
</template>

<script>
	import {
		ethers
	} from 'ethers'; 
	import {
		useContract
	} from '@/common/api/core.js'  
	import Mining from '@/common/config/Mining.json'; 
	let LPTokenContract;
	let PUMPContract;
	let UsdtContract;  

	import {
		add_url,
		getRemoveLink
	} from '@/common/api/core.js'
	import {
		ready,
		getProvider,
		getWalletAddress,
		Contract,
		getWallet
	} from '@/common/ethersConnect'
	import {
		contract,
		getters
	} from '@/store/contract.js'
	export default {
		data() {
			return {
				add_url: add_url(),
				removeLink:getRemoveLink(),
				// swap_url: swap_url(),
				//余额
				club_balance: '', //
				usdt_balance: '',
				ht_balance: '',
				//总计
				club_total: '',
				usdt_total: '', 
				stake_num: '', //抵押数量
				cancle_num: '', //撤出储量 
				// earned_num: '',
				lp_banance: 0, //LP余额
				pool_stake: '',
				// LPTotal: '',
				// lp_rate: '',
				// club_total: '',
				// is_approve: false, //是否授权  
				priceLast: 0,
			};
		},
		watch: {
			cancle_num(val) {
				let pool_stake = ethers.utils.formatUnits(ethers.BigNumber.from(this.pool_stake));
				if (pool_stake < val) {
					this.cancle_num = pool_stake;
				}
			},
			stake_num(val) {
				let lp_banance = ethers.utils.formatUnits(ethers.BigNumber.from(this.lp_banance));
				if (lp_banance < val) {
					this.stake_num = lp_banance;
				}
			},
			address: {
				handler: async function(val) {
					if (val) {
						this.getData();
					}
				},
				immediate: true
			},
		},
		filters: {
			filtersUnits(num) {
				if (!num) return 0;
				//将wei的数量格式化表示单位数量的十进制字符串
				return ethers.utils.formatUnits(ethers.BigNumber.from(num));
			},
			filtersUnitsPrice(num) {
				if (!num) return 0;
				return ethers.utils.formatUnits(ethers.BigNumber.from(num));
			}
		},
		computed: {
			address() {
				return this.$store.state.address;
			},
		},
		async mounted() { 
			let that = this;
			this.getData();
			setInterval(that.getData, 5000);
		},
		methods: {
			async getData() {
				if (!ready()) return false; //如果没有链接钱包则返回错误 
				// console.log(this.address);s
				LPTokenContract = await useContract('LP');
				PUMPContract = await useContract('Token')
				UsdtContract = await useContract('USDT'); 
				//计算club
				let club_balance = await PUMPContract.balanceOf(this.address);
				this.club_balance = club_balance.toString();
				//USDT余额
				let usdt_balance = await UsdtContract.balanceOf(this.address);
				this.usdt_balance = usdt_balance.toString();
				//计算火腿 
				let ht_balance = await getWallet().getBalance();
				this.ht_balance = ht_balance.toString();
				//LP
				let lp_banance = await LPTokenContract.balanceOf(this.address);
				this.lp_banance = lp_banance.toString();
			},
		}
	};
</script>
<style lang="scss">
	@import "/src/uni.scss";

	.page {
		padding: 15px 0px;
	}

	.box-card {
		h3 {
			color: $u-type-success-dark;
			font-weight: bold;
			font-size: 18px;
			text-align: center;
		}

		.item {
			display: flex;
			line-height: 30px;
			justify-content: space-between;

			.label {
				color: #666;
				font-size: 16px;
				// border: 1px solid #000;
			}

			.value {
				font-size: 14px;
				color: #333;
				font-weight: bold;
			}
		}

		.el-dialog__body {
			text-align: center;
		}
	}

	.el-card {
		border: none;
	}

	.el-message {
		border-radius: 8px;
	}

	.el-main {
		padding: 0px;
	}

	@media (min-width: 1200px) {
		.box-card {
			width: 350px;
			margin: 0 auto;
		}

		.el-main {
			padding: 20px;
		}
	}

	// .el-dialog__header {
	// 	background-color: #f4f4f4;
	// }

	.bottom {
		text-align: center;
		margin-top: 20px;
		display: flex;
		justify-content: space-between;

		a {
			display: block;
			line-height: 20px;
			background-color: #1a74cc;
			// background: linear-gradient(45deg, #0d4168, #1a5a0e); 
			padding: 10px 20px;
			border-radius: 50px;
			color: #fff !important;
			width: 120px;
			text-decoration: none;
			
		} 
		a:active {text-decoration: none;} 
	}
</style>
