<template>
	<el-card class="box-card" shadow="never" style="margin-top: 20px;">
		<h3>{{$t('Overview')}}</h3>
		<div class="item">
			<div class="label">{{$t('total_tiger_issuance')}}</div>
			<div class="value">10000</div>
		</div>
		<div class="item">
			<div class="label">{{$t('tiger_burned')}}</div>
			<div class="value">{{burned_num|toFloor}}</div>
		</div>
		<div class="item">
			<div class="label">{{$t('tiger_circulation')}}</div>
			<div class="value">{{totalSupply|filtersUnits|toFloor}}</div>
		</div>
	</el-card>
</template>

<script>
	import {
		contract
	} from '@/store/contract.js'
	import {
		useContract
	} from '@/common/api/core.js'
export default{
	data(){
		return {
			totalSupply:0
		}
	},
	computed:{
		burned_num(){
			if(Number(this.totalSupply)){
				return 10000-(Number(this.totalSupply)/10**18); 
			}
			return 0;
		}
	}, 
	async mounted() {
		setInterval(this.mounted, 5000); 
	},
	methods:{
		async mounted(){
			let TokenContract = useContract('Token');
			this.totalSupply=await TokenContract.totalSupply(); 
		}
	}
}
</script>

<style>
</style>
