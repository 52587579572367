<template>
	<div>
	
		<el-card class="box-card" shadow="never" style="margin-top: 20px;">
			<h3>{{$t('Pairs')}}</h3>
			<div class="item">
				<div class="label">{{$t('total_club')}}</div>
				<div class="value">
					{{club_total|filtersUnits|toFloor}}
				</div>
			</div>
			<div class="item">
				<div class="label">{{$t('Total_USDT')}}</div>
				<div class="value">{{usdt_total|filtersUnits|toFloor}}</div>
			</div>
			<div class="item">
				<div class="label">{{$t('priceLast')}}</div>
				<div class="value">{{priceLast}}</div>
			</div>
			<div class="bottom" style="position: relative;">
				<el-button type="primary" size="medium" style="width: 50%;" @click="buyDialogVisible=true;buy_num=null">
					{{$t('buy')}}
				</el-button>
				<el-button type="primary" size="medium" style="width: 50%;" @click="sellDialogVisible=true;sell_num=null">
					 
					{{$t('Sell')}}
				</el-button>
			</div>
			<!-- <el-button @click="sync">sync</el-button> -->
		</el-card>
		<!-- 出售弹窗 -->
		<el-dialog :title="$t('Sell')" width="350px" :visible.sync="sellDialogVisible">
			<h5 style="display: flex; align-items: center;justify-content: space-between;">TIGER</h5>
			<p>{{$t('You_have')}}: {{club_balance|filtersUnits}}</p>
			<div style="display: flex; justify-content: space-between;">
				<el-input v-model="sell_num"><i slot="suffix" class="el-input__icon" style="margin-right:20px">TIGER</i>
				</el-input>
				<el-button size="mini" type="primary" @click="max_sell_num">{{$t('MAX')}}</el-button>
			</div>
			<div> {{$t('Expected_to_get')}} {{usdt_amount|filtersUnits|toFloor}} USDT</div>
			<span slot="footer" class="dialog-footer">
				<el-button @click="sellDialogVisible = false">{{$t('Cancle')}}</el-button>
				<template v-if="is_approve">
					<!-- <el-button type="info">授权</el-button> -->
					<el-button type="primary" @click="sell">{{$t('OK')}}</el-button>
				</template>
				<template v-else>
					<el-button type="primary" @click="approve">授權</el-button>
					<el-button type="info">{{$t('OK')}}</el-button>
				</template>
			</span>
		</el-dialog>
		<!-- 购买弹窗 -->
		<el-dialog :title="$t('buy')" width="350px" :visible.sync="buyDialogVisible">
			<h5 style="display: flex; align-items: center;justify-content: space-between;">USDT</h5>
			<p>{{$t('You_have')}}: {{usdt_balance|filtersUnits|toFloor}}</p>
			<div style="display: flex; justify-content: space-between;">
				<el-input v-model="buy_num" :placeholder="$t('Amount_limit')+`${buyMaxNum}`"><i slot="suffix" class="el-input__icon" style="margin-right:20px">USDT</i>
				</el-input>
				<!-- <el-button size="mini" type="primary" @click="max_buy_num">{{$t('MAX')}}</el-button> -->
			</div>
			<div>{{$t('Expected_to_get')}} {{token_amount|filtersUnits|toFloor}} TIGER</div>
			<span slot="footer" class="dialog-footer">
				<el-button @click="buyDialogVisible = false">{{$t('Cancle')}}</el-button>
				<template v-if="buyIsApprove">
					<el-button type="primary" @click="buy">{{$t('OK')}}</el-button>
				</template>
				<template v-else>
					<el-button type="primary" @click="buyApprove">授權</el-button>
					<el-button type="info">{{$t('OK')}}</el-button>
				</template>
			</span>
		</el-dialog>
	
	</div>
</template>

<script> 
	import {
		ethers
	} from 'ethers';
	// import Big from 'big.js'; 
	let ClubContract;
	let UsdtContract; 
	import axios from 'axios';
	import {
		ready, 
		getWalletAddress,
		Contract,
		getWallet
	} from '@/common/ethersConnect'

	import {
		contract
	} from '@/store/contract.js'

	import {
		useContract
	} from '@/common/api/core.js'
	import deployments from '@/common/deployments.json';
	import {toggle} from '@/components/mixins/toggle.js'
	import {priceLast} from '@/common/api/swap.js'
	export default {
		mixins:[toggle], 
		data() {
			return {
				sellDialogVisible: false, //出售弹窗
				sell_num: 0,
				buy_num: null,
				usdt_amount: 0,
				token_amount: 0,
				//余额
				club_balance: '', // 
				usdt_balance: '', //钱包USDT余额
				//总计
				club_total: '',
				usdt_total: '', 
				lp_banance: 0, //LP余额 
				is_approve: false, //是否授权出售
				buyIsApprove: false, //是否授权购买 
				buyDialogVisible: false,
				priceLast: 0,
			};
		},
		computed:{
			buyMaxNum(){ //当前购买最大额度
				if(!this.usdt_total)return 0;
			// console.log(this.usdt_total);
				let usdt_total= ethers.utils.formatUnits(ethers.BigNumber.from(this.usdt_total))
				return (usdt_total*0.05).toFixed(4);
			}
		},
		watch: { 
			async sell_num(val) {
				if (parseFloat(val) > 0) {
					let banance = ethers.utils.formatUnits(ethers.BigNumber.from(this.club_balance));
					if (parseFloat(banance) < parseFloat(val)) {
						this.sell_num = banance;
					}
					let {
						getAmountsOut
					} = await useContract('UniswapV2Router');
					//售后价格
					let num = await getAmountsOut(ethers.utils.parseUnits(val), [
						deployments['Token'].address,
						deployments['USDT'].address
					]);
					this.usdt_amount = num[1].toString(); //预估获取usdt
				} else {
					return 0;
				}
			},
			async buy_num(val) {
				if (parseFloat(val) > 0) {
					let banance = ethers.utils.formatUnits(ethers.BigNumber.from(this.usdt_balance));
					//购买数量不能超过总共数量
					if (parseFloat(banance) < parseFloat(val)) {
						//购买数量约束 
						this.buy_num = banance;
					}
					//矿池数量 
					let usdt_total= ethers.utils.formatUnits(ethers.BigNumber.from(this.usdt_total))
					
					if (parseFloat(usdt_total*0.05) < parseFloat(val)) {
						//购买数量约束 
						this.buy_num = (usdt_total*0.05).toFixed(4).toString();
					}
					
					// console.log(usdt_total*0.05);
					//计算预估
					let {
						getAmountsOut
					} = await useContract('UniswapV2Router');
					
					console.log(val);
					//售后价格
					let num = await getAmountsOut(ethers.utils.parseUnits(val), [ 
						deployments['USDT'].address,
						deployments['Token'].address
					]);
					this.token_amount = num[1].toString(); //预估获取usdt
				} else {
					return 0;
				}
			}
		},
		filters: {
			filtersUnits(num) {
				if (!num) return 0;
				//将wei的数量格式化表示单位数量的十进制字符串
				return ethers.utils.formatUnits(ethers.BigNumber.from(num));
			}, 
		}, 
		created() { //初始化地址
			this.$watch(() => contract.default_account, val => {
				if (val) {
					// console.log(val);
					this.mounted()
				}
			}, {
				immediate: true,
			})
		}, 
		async mounted() { 
			setInterval(this.mounted, 5000); 
		},
		methods: {
			
			async approve() {
				let that = this;
				// this.fullscreenLoading = true;
				let {
					approve
				} = await useContract('Token');
				console.log(approve);
				await approve(deployments['UniswapV2Router'].address,
					'0xffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff').then(res => {
					const loading = that.$loading({
						text: '授权中',
						background: 'rgba(0, 0, 0, 0.7)'
					});
					// that.stakeDialogVisible = false;
					res.wait().then(res2 => {
						loading.close();
						that.is_approve = true;
					})
				});
			},
			async buyApprove() {
				let that = this;
				// this.fullscreenLoading = true;
				let {
					approve
				} = await useContract('USDT');
				console.log(approve);
				await approve(deployments['UniswapV2Router'].address,
					'0xffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff').then(res => {
					const loading = that.$loading({
						text: '授权中',
						background: 'rgba(0, 0, 0, 0.7)'
					});
					// that.stakeDialogVisible = false;
					res.wait().then(res2 => {
						loading.close();
						that.buyIsApprove = true;
					})
				});

			},
			async mounted() {
				// console.log(777);
				if (!ready()) return false; 
				const address = this.$store.state.address;
				let LPContract = useContract('LP');
				let ClubContract = useContract('Token');
				let USDTContract = useContract('USDT');
				let Reserves = await LPContract.getReserves();
				let token0 = await LPContract.token0();
				if (deployments['USDT'].address == token0) {
					this.usdt_total = Reserves['reserve0'];
					this.club_total = Reserves['reserve1'];
				} else {
					this.usdt_total = Reserves['reserve1'];
					this.club_total = Reserves['reserve0'];
				}

				// //判断是否授权
				let calls = [
					ClubContract.allowance(getWalletAddress(), deployments['UniswapV2Router'].address),
					ClubContract.balanceOf(getWalletAddress()),
				]
				axios.all(calls).then(
					axios.spread((allowance, club_balance) => {
						// console.log(allowance);
						this.club_balance = club_balance;
						if (!allowance.lt(club_balance.toString())) {
							this.is_approve = true; //已经授权 
						}
					})
				)

				// //判断是否授权
				calls = [
					USDTContract.allowance(getWalletAddress(), deployments['UniswapV2Router'].address),
					USDTContract.balanceOf(getWalletAddress()),
				]
				axios.all(calls).then(
					axios.spread((allowance, usdt_balance) => {
						// console.log(allowance);
						// console.log(usdt_balance);
						this.usdt_balance = usdt_balance;
						if (!allowance.lt(usdt_balance.toString())) {
							this.buyIsApprove = true; //已经授权 
						}
						// console.log(this.buyIsApprove);
					})
				);
				this.priceLast=await priceLast();
				// console.log(await priceLast());
			},
			max_sell_num() { //最大销量
				let lp_banance = ethers.utils.formatUnits(ethers.BigNumber.from(this.club_balance));
				this.sell_num = lp_banance;
			},
			max_buy_num() { //最大销量
				let lp_banance = ethers.utils.formatUnits(ethers.BigNumber.from(this.usdt_balance));
				this.buy_num = lp_banance;
			},
			async sync() {
				let {
					sync
				} = await useContract('LP');
				sync().then(res => {
					console.log(res);
				})
			},
			async sell() { //销售合约 
				let that = this;
				//售后价格  
				let time = Date.parse(new Date()).toString(); //获取到毫秒的时间戳，精确到毫秒
				time = time.substr(0, 10); //精确到秒  
				let {
					swapExactTokensForTokensSupportingFeeOnTransferTokens
				} = await useContract('UniswapV2Router');

				// console.log(eval(parseInt(time) + 3600));
				await swapExactTokensForTokensSupportingFeeOnTransferTokens(ethers.utils.parseUnits(this.sell_num), 0,
					[
						deployments['Token'].address,
						deployments['USDT'].address
					], getWalletAddress(), time + 3600).then(res => {
					const loading = that.$loading({
						text: '出售中',
						background: 'rgba(0, 0, 0, 0.7)'
					});
					that.sellDialogVisible = false;
					res.wait().then(res2 => {
						loading.close();
						// that.is_approve = true;
					})
					// console.log(res);
				});

			},
			async buy() { //购买
				let that = this;
				//售后价格  
				let time = Date.parse(new Date()).toString(); //获取到毫秒的时间戳，精确到毫秒
				time = time.substr(0, 10); //精确到秒  
				let {
					swapExactTokensForTokensSupportingFeeOnTransferTokens
				} = await useContract('UniswapV2Router');


				await swapExactTokensForTokensSupportingFeeOnTransferTokens(ethers.utils.parseUnits(this.buy_num), 0,
					[
						deployments['USDT'].address,
						deployments['Token'].address
					], getWalletAddress(), time + 3600).then(res => {
					const loading = that.$loading({
						text: '购买中',
						background: 'rgba(0, 0, 0, 0.7)'
					});
					that.buyDialogVisible = false;
					res.wait().then(res2 => {
						loading.close();
						// that.is_approve = true;
					})
					// console.log(res);
				});

			},
			 
		}
	};
</script>
<style lang="scss">
	@import "/src/uni.scss";
	// .page {
	// 	padding: 15px 0px;
	// }

	.box-card {
		h3 {
			color: $u-type-success-dark;
			font-weight: bold;
			font-size: 18px;
			text-align: center;
		}

		.item {
			display: flex;
			line-height: 30px;
			justify-content: space-between;

			.label {
				color: #666;
				font-size: 16px;
				// border: 1px solid #000;
			}

			.value {
				font-size: 14px;
				color: #333;
				font-weight: bold;
			}
		}

		.el-dialog__body {
			text-align: center;
		}
	}

	.el-card {
		border: none;
	}

	.el-message {
		border-radius: 8px;
	}

	.el-main {
		padding: 0px;
	}

	@media (min-width: 1200px) {
		.box-card {
			width: 350px;
			margin: 0 auto;
		}

		.el-main {
			padding: 20px;
		}
	}

	.el-dialog__header {
		background-color: #f4f4f4;
	}

	.bottom {
		text-align: center;
		margin-top: 20px;

		a {
			display: block;
			line-height: 20px;
			// color: $u-type-success-dark;
		}
	}
</style>
