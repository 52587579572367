<template>
	<vue-pull-refresh :on-refresh="onRefresh">
		<div class="page">
			<el-container>
				<el-main>
					<!-- 信息组件 -->
					<info ref="info"></info>
					<!-- 交易组件 -->
					<swap ref="swap"></swap>
					<!-- 钱包组件 -->
					<wallet ref="wallet"></wallet>
				</el-main>
			</el-container>
		</div>
	</vue-pull-refresh>
</template>
<script>
	import wallet from "./components/wallet"
	import swap from "./components/swap"
	import info from "./components/info"
	import VuePullRefresh from 'vue-pull-refresh';
	export default {
		components: {
			wallet,
			swap,
			info,
			'vue-pull-refresh': VuePullRefresh
		},
		methods: {
			onRefresh() {
				// console.log(this.$refs.swap);
				this.$refs.swap.mounted(); 
				this.$refs.wallet.getData(); 
			},
		}
	};
</script>
